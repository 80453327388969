@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.container {
  width: toRem(700);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: toRem(20);
}

.title {
  margin-bottom: toRem(19);
}

.child {
  width: 100%;
  & > * {
    padding-bottom: toRem(10);
  }
}

.checkboxLabel {
  color: $color-purple-500;
  font-weight: bold;
  font-size: toRem(20);
  text-transform: uppercase;
}

.button {
  padding-top: toRem(20);
  padding-bottom: toRem(20);
  display: flex;
  justify-content: center;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-white-100;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
