@import 'src/styles/abstracts/functions.scss';

.chipsInputContainer {
  display: flex;
  flex-direction: column;
  padding-top: toRem(16);
  
  :global(.MuiFormHelperText-root) {
    position: absolute;
    bottom: toRem(-20);
    margin: 0;
  }
}
