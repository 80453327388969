@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.container {
  width: toRem(700);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: toRem(20);
}

.title {
  margin-bottom: toRem(19);
}

.child {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    padding-bottom: toRem(10);
  }
}

.checkboxLabel {
  color: $color-purple-500;
  font-weight: bold;
  font-size: toRem(20);
}

.button {
  padding-top: toRem(20);
  padding-bottom: toRem(20);
  display: flex;
  justify-content: center;
}

.checkboxContainer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}
